import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { toggleSidebar } from '../../store'
import { Container, Label, Menu, Icon, Image } from 'semantic-ui-react'
import { MenuProps } from '../Menu'
const { SocialIcon } = require('react-social-icons')
const logo = require('../../../assets/elite-concrete.png')

interface HeaderMenuProps extends MenuProps {
  dispatch?: Dispatch<any>
  inverted?: boolean
}
// https://itnext.io/responsive-background-images-using-react-hooks-941af365ea1f
export const HeaderMenu = ({
  items,
  pathname,
  Link,
  inverted,
  dispatch
}: HeaderMenuProps) => (
  <Container>
    {/* TODO: change this fucking navbar */}
    <Menu size="large" secondary inverted={inverted}>
      <Menu.Item
        as="a"
        className="mobile only"
        icon="sidebar"
        onClick={() => dispatch && dispatch(toggleSidebar())}
      />
      <Menu.Item position="right" className="mobile only">
        <SocialIcon
          url="https://g.page/r/CSs-hHsEbpHcEBM/review"
          network="google"
          style={{ marginRight: '10px' }}
          target="_blank"
        />
        <SocialIcon
          url="https://www.facebook.com/Clarks-concrete-and-construction-103523801075619"
          network="facebook"
          style={{ marginRight: '10px' }}
          target="_blank"
        />
        <SocialIcon
          url="https://www.instagram.com/eliteconcreteandconstruction"
          network="instagram"
          style={{ marginRight: '10px' }}
          target="_blank"
        />
        <div className="ui image">
          <Image src={logo} alt="medium" style={{ width: '100px' }} />
        </div>
      </Menu.Item>

      {items.map(item => {
        const active = item.exact
          ? pathname === item.path
          : pathname.startsWith(item.path)
        return (
          <Menu.Item
            as={Link}
            className="mobile hidden"
            name={item.name}
            to={item.path}
            key={item.path}
            active={active}
            position="right"
          />
        )
      })}
      <Menu.Item position="right" className="mobile hidden">
        <SocialIcon
          url="https://g.page/r/CSs-hHsEbpHcEBM/review"
          network="google"
          style={{ marginRight: '10px' }}
          target="_blank"
        />
        <SocialIcon
          url="https://www.facebook.com/Clarks-concrete-and-construction-103523801075619"
          network="facebook"
          style={{ marginRight: '10px' }}
          target="_blank"
        />
        <SocialIcon
          url="https://www.instagram.com/eliteconcreteandconstruction"
          network="instagram"
          style={{ marginRight: '10px' }}
          target="_blank"
        />
        <div className="ui image">
          <img src={logo} alt="medium" style={{ width: '100px' }} />
        </div>
      </Menu.Item>
    </Menu>
  </Container>
)

export default connect()(HeaderMenu)
