import { Link } from 'gatsby'
import * as React from 'react'
import HeaderMenu from './HeaderMenu/HeaderMenu'
import SidebarMenu from './SidebarMenu/SidebarMenu'
import { Segment, Icon, Container, Sidebar, Grid } from 'semantic-ui-react'
import '../css/styles.css'
import '../css/responsive.css'
import '../css/semantic.min.css'
import 'prismjs/themes/prism-okaidia.css'
import { Provider } from 'react-redux'
import { store } from '../store'

export const menuItems = [
  { name: 'Home', path: '/', exact: true, icon: 'home', inverted: true },
  // { name: 'About Us', path: '/about', exact: true, icon: 'home', inverted: true },
  { name: 'About', path: '/about/', exact: true, icon: 'info circle' },
  { name: 'Services', path: '/services/', exact: false, icon: 'wrench' },
  { name: 'Contact Us', path: '/contact/', exact: false, icon: 'phone' }
]

export interface LayoutProps {
  location: {
    pathname: string
  }
  children: any
}

const Layout = (props: LayoutProps) => {
  const { pathname } = props.location
  const isHome = pathname === '/'

  return (
    <Provider store={store}>
      <Sidebar.Pushable as={Segment}>
        <SidebarMenu
          Link={Link}
          pathname={pathname}
          items={menuItems}
          visible={false}
        />

        <Sidebar.Pusher style={{ minHeight: '100vh' }}>
          {/* Header */}
          {isHome ? null : (
            <HeaderMenu Link={Link} pathname={pathname} items={menuItems} />
          )}

          {/* Render children pages */}
          <div style={{ paddingBottom: 60 }}>{props.children}</div>

          {/* Footer */}
          <Segment
            inverted
            vertical
            style={{ position: 'absolute', bottom: 0, width: '100%' }}
          >
            <Container>
              <Grid>
                <Grid.Row>
                  <Grid.Column width={8} textAlign="left">
                    <div>
                      <Icon name="copyright outline" />©
                      {new Date().getFullYear()} Elite Concrete
                    </div>
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    <div>
                      <a
                        href="https://instagram.com/appmestudios"
                        target="_blank"
                      >
                        <Icon name="code" />
                      </a>
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Container>
          </Segment>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </Provider>
  )
}

export default Layout

export const withLayout = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) =>
  class WithLayout extends React.Component<P & LayoutProps> {
    render() {
      return (
        <Layout location={this.props.location}>
          <WrappedComponent {...this.props} />
        </Layout>
      )
    }
  }
